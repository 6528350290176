import * as React from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { GrDocumentPdf, GrDocumentMissing } from "react-icons/gr";
import { Vehicle } from "../../models/OrderStatusResponse";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { OptionCodeData } from "../../models/OptionCodeData";
import { LoadingButton } from "@mui/lab";

const columns: GridColDef[] = [
  { field: "code", headerName: "Option Code", 
  flex: 2,
  sortable: true },
  {
    field: "isStandard",
    headerName: "Standard",
    flex: 1,
    type: "boolean",
  },
  {
    field: "name",
    headerName: "Name",
    hideable: true,
    flex: 5,
  },
  {
    field: "longName",
    headerName: "Long Name",
    hideable: true,
    flex: 5,
  },
  {
    field: "displayName",
    headerName: "Display Name",
    sortable: false,
    hideable: true,
    flex: 5,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.longName === undefined
        ? params.row.name === undefined
          ? ""
          : params.row.name
        : params.row.longName,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 5,
    hideable: true
  },
];

const OptionCodesGrid = ({ vehicle }: { vehicle: Vehicle }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [areOptionsAvailable, setAreOptionsAvailable] = React.useState(false);
  const [optionData, setOptionData] = React.useState<any>({});
  const [matchingOptionData, setMatchingOptionData] = React.useState<
    Array<OptionCodeData>
  >([]);
  const [optionErrorMessage, setOptionErrorMessage] = React.useState("");
  React.useEffect(() => {
    if (
      vehicle.trimCode === undefined ||
      vehicle.cllp === undefined ||
      vehicle.optionCodes === undefined
    ) {
      console.log("No option codes available");
      setIsLoading(false);
      return;
    }
    // https://us-central1-waitingforjeep.cloudfunctions.net/api-tracker-optionCodes
    // ${process.env.GATSBY_API_URL}
    fetch(
      `${process.env.GATSBY_API_URL}/api/v1/tracker/optioncodes?trimCode=${vehicle.trimCode}&packageCode=${vehicle.cllp}`
    )
      .then(
        (res) => {
          if (res.status !== 200) {
            return Promise.reject(res.json());
          }

          return res.json();
        },
        (error) => {
          console.error("API Error: " + error);
          return Promise.reject("Error fetching matching option names");
        }
      )
      .then(
        (result) => {
          setIsLoading(false);
          setOptionData(result);
          var updatedOptions = [] as Array<OptionCodeData>;
          vehicle.optionCodes?.forEach((oc) => {
            if (result[oc] !== undefined) {
              updatedOptions.push(result[oc]);
            } else if (oc === vehicle.trimCode) {
              updatedOptions.push({ code: oc, name: vehicle.model, isStandard: true, longName: vehicle.model });
            } else {
              updatedOptions.push({ code: oc });
            }
          });
          setMatchingOptionData(updatedOptions);
          setAreOptionsAvailable(true);
        },
        (error) => {
          setIsLoading(false);
          if (typeof error === "object" && error.message !== undefined) {
            setOptionErrorMessage(error.message);
          } else {
            setOptionErrorMessage(
              "Option data is not available for this vehicle."
            );
          }
        }
      )
      .catch((error) => {
        setIsLoading(false);
        setOptionErrorMessage("Option data is not available for this vehicle.");
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <Grid item container xs={12} className="py-3">
<LoadingButton loading variant="outlined" className="m-auto" 
loadingPosition="start"
startIcon={<CircularProgress/>}
>
          Searching for Option Data
        </LoadingButton>
        </Grid>
      ) : null}
      {optionErrorMessage !== "" ? (
        <Typography variant="subtitle2" align="center" className="pb-3">
                  {optionErrorMessage}
                </Typography>
      ) : null}
      {!areOptionsAvailable ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnGap={2}
          rowGap={2}
        >
          {vehicle.optionCodes?.map((item: string, index: number) => (
            <Box key={index}>
              <Chip
                label={item}
                size="medium"
                variant="filled"
                sx={{ borderRadius: "5px" }}
                onClick={() => {}}
              />
            </Box>
          ))}
        </Grid>
      ) : (
        <Grid container>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.code}
              rows={matchingOptionData}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[25]}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    longName:false,
                    description: false,
                    displayName: false,
                  },
                },
                sorting: {
                  sortModel: [{ field: 'name', sort: 'desc' }],
                },
              }}
            />
          </div>
        </Grid>
      )}
    </div>
  );
};

export default OptionCodesGrid;
