import * as React from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { GrDocumentPdf, GrDocumentMissing } from "react-icons/gr";

const WindowStickerCard = ({ vin }: { vin: string }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAvailable, setIsAvailable] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState("");

  React.useEffect(() => {
    if (vin === "") {
      setIsLoading(false);
      return
    }

    fetch(`${process.env.GATSBY_API_URL}/api/v1/tracker/windowSticker?vin=${encodeURIComponent(vin)}`)
      .then(
        (res) => {
          if (res.status !== 200) {
            return Promise.reject("API Status - " + res.status);
          }

          return res.json();
        },
        (error) => {
          console.error("API Error: " + error);
          return Promise.reject(error);
        }
      )
      .then(
        (result) => {
          setIsLoading(false);
          if (result.isAvailable) {
            setIsAvailable(true);
            setPdfUrl(result.url);
          }
        },
        (error) => {
          setIsLoading(false);
          console.error("Error:", error);
        }
      )
      .catch((error) => {
        setIsLoading(false);
        console.error("Data Error:", error);
      });
  }, []);

  return (
    <Grid item xs={12} md={6} className="px-1">
      <Card sx={{ minWidth: "200px" }}>
        <CardActionArea disabled={!isAvailable} onClick={() => window.open(pdfUrl, "_blank")}>
        <CardContent>
          <Typography variant="overline" component="h3">
            Window Sticker
          </Typography>
          {isLoading ? (
            <Stack>
              <LinearProgress />
              <Typography variant="button">Searching</Typography>
            </Stack>
          ) : isAvailable ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Avatar variant="rounded">
                <GrDocumentPdf />
              </Avatar>
              <Typography variant="button">Available</Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Avatar variant="rounded">
                <GrDocumentMissing />
              </Avatar>
              <Typography variant="button">Not Yet</Typography>
            </Stack>
          )}
        </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default WindowStickerCard;
