import * as React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

const DataCardSmall = ({title, data} : {title: string, data: string})  => {
  return (
    
<div className="px-sm-1">
<Card>
                <CardContent>
                  <Typography variant="overline" component="h3">
                  {title} 
                  </Typography>
                  <Typography variant="h4">
                  {data}
                  </Typography>
                </CardContent>
              </Card>
  </div>
  );
};

export default DataCardSmall;
