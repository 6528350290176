import * as React from "react";
import Layout from "../components/Layout";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getQueryParams, setQueryParams } from "react-use-query-param-string";
import { useEffect } from "react";
import { navigate } from "gatsby";
import { OrderStatusResponse } from "../models/OrderStatusResponse";
import { RouteComponentProps } from "@reach/router";
import DataCardSmall from "../components/OrderStatus/DataCardSmall";
import WindowStickerCard from "../components/OrderStatus/WindowStickerCard";
import BuildSheetCard from "../components/OrderStatus/BuildSheetCard";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import GetAlertsButton from "../components/OrderStatus/GetAlertsButton";
import { StaticImage } from "gatsby-plugin-image";
import {BiShow, BiHide} from "react-icons/bi";
import Header from "../components/Layout/Header";
import OptionCodesGrid from "../components/OrderStatus/OptionCodesGrid";

interface Props
  extends RouteComponentProps<{
    location: {
      state: {
        orderStatus?: OrderStatusResponse;
        lastName?: string;
        orderNumber?: string;
      };
    };
    orderStatusData?: OrderStatusResponse;
  }> {}

const OrderStatusPage = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [orderStatus, setOrderStatus] =
    React.useState<OrderStatusResponse | null>(null);
  const [isPiiHidden, setIsPiiHidden] = React.useState(false);
  const [lastName, setLastName] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");

  useEffect(() => {
    if (props.orderStatusData) {
      setOrderStatus(props.orderStatusData);
      setOrderNumber(props.orderStatusData.von.toString());
      setIsLoading(false);
      return;
    }

    if (props.location?.state?.orderStatus) {
      setOrderStatus(props.location.state.orderStatus);
      setIsLoading(false);
      if (props.location.state.orderNumber && props.location.state.lastName) {
        setQueryParams({
          lastName: props.location.state.lastName,
          orderNumber: props.location.state.orderNumber,
        });

        if (props.location.state.lastName !== null && props.location.state.lastName !== undefined) {
          setLastName(props.location.state.lastName);
        }
        
        if (props.location.state.orderNumber !== null && props.location.state.orderNumber !== undefined) {
          setOrderNumber(props.location.state.orderNumber);
        }
      }

      return;
    }

    const query = getQueryParams();
    if (
      !("lastName" in query && "orderNumber" in query) ||
      query.lastName === null ||
      query.orderNumber === null ||
      query.lastName === "" ||
      query.orderNumber === "" ||
      query.orderNumber.length !== 8
    ) {
      navigate("/");
      return;
    }

    setLastName(query.lastName as string);
    setOrderNumber(query.orderNumber as string);

    fetch(
      `${
        process.env.GATSBY_API_URL
      }/api/v1/tracker/orderStatus?lastName=${encodeURIComponent(
        query.lastName as string
      )}&orderNumber=${encodeURIComponent(query.orderNumber as string)}`
    )
      .then(
        (res) => {
          if (res.status !== 200) {
            return Promise.reject("API Status - " + res.status);
          }

          return res.json();
        },
        (error) => {
          console.error("API Error: " + error);
          return Promise.reject(error);
        }
      )
      .then(
        (result) => {
          setIsLoading(false);
          setOrderStatus(result);
        },
        (error) => {
          setIsLoading(false);
          navigate("/");
        }
      )
      .catch((error) => {
        setIsLoading(false);
        navigate("/");
      });
  }, []);

  return (
    <Layout>
      <Header
      pageTitle={orderNumber !== "" && lastName !== "" ? `${orderNumber} Order Status for ${lastName} - Powered by WaitingForJeep.com` : (orderNumber !== "" ? `${orderNumber} Order Status - Powered by WaitingForJeep.com` : "Order Status - Powered by WaitingForJeep.com")}
      ogTitle={orderNumber !== "" && lastName !== "" ? `${orderNumber} Order Status for ${lastName} - Powered by WaitingForJeep.com` : (orderNumber !== "" ? `${orderNumber} Order Status - Powered by WaitingForJeep.com` : "Order Status - Powered by WaitingForJeep.com")}
      description="Track your custom order and get alerted as it moves from one stage to the next."
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => setIsLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {orderStatus ? (
        <Container>
          <Grid
            container
            className="py-5"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="">
              <StaticImage
                src="../images/icon.png"
                alt="WaitingForJeep"
                placeholder="blurred"
                width={100}
                height={100}
                loading="eager"
                objectFit="contain"
              ></StaticImage>
            </Grid>
            <Grid item className="py-3 px-sm-1 px-lg-3">
              <Typography variant="h1" component="div" align="center">
                Order Status
              </Typography>
            </Grid>
            <Grid
              container
              className="p-sm-1 p-lg-3"
              direction="row"
              justifyContent="center"
              alignItems="center"
              rowGap={2}
            >
              <Grid item xs={12} lg={6}>
                <img
                  src={`${process.env.GATSBY_API_URL}/api/v1/images/jeep/${orderStatus.vehicle.imageUrl}`}
                  width="100%"
                  height="100%"
                  style={{
                    borderRadius: "20px",
                    boxShadow: "0 0 20px 20px #2c2c2c",
                  }}
                ></img>
              </Grid>
              <Grid item container xs={12} lg={6} className="p-sm-1 p-lg-3">
                <Stack
                  direction="column"
                  alignItems="center"
                  sx={{ width: "100%" }}
                  className="p-3"
                  rowGap={1}
                >
                  <GetAlertsButton />
                  <Typography variant="subtitle2">Coming soon!</Typography>
                </Stack>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  // columnGap={2}
                  rowGap={2}
                  className="p-0"
                >
                  <Grid item xs={12} md={4}>
                    <DataCardSmall
                      title="Order Number"
                      data={isPiiHidden ? "********" : orderStatus.von.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <DataCardSmall
                      title="Current Status"
                      data={`${orderStatus.currentOrderStatusCode} - ${orderStatus.currentOrderStatusName}`}
                    />
                  </Grid>
                  {!orderStatus.isDelivered &&
                  orderStatus.currentStatus &&
                  orderStatus.previousStatus ? (
                    <Grid item xs={12} md={orderStatus.hasVin ? 12 : 6}>
                      <DataCardSmall
                        title="Last Change"
                        data={
                          Math.round(
                            (new Date().getTime() -
                              (orderStatus.currentStatus.statusUpdateDate ??
                                0)) /
                              (24 * 60 * 60 * 1000)
                          ).toString() + " days"
                        }
                      />
                    </Grid>
                  ) : null}
                  {orderStatus.isDelivered ? (
                    <Grid item xs={12}>
                      <DataCardSmall
                        title="Delivered In"
                        data={
                          Math.round(
                            ((orderStatus.currentStatus.statusUpdateDate ?? 0) -
                              (orderStatus.timeline[0].statusUpdateDate ?? 0)) /
                              (24 * 60 * 60 * 1000)
                          ).toString() + " days"
                        }
                      />
                    </Grid>
                  ) : null}
                  {!orderStatus.hasVin ? (
                    <Grid item xs={12} md={6}>
                      <DataCardSmall title="VIN Assigned" data={"Not Yet"} />
                    </Grid>
                  ) : null}
                  {orderStatus.vin ? (
                    <Grid item xs={12}>
                      <DataCardSmall title="VIN" data={isPiiHidden ? "*****************" : orderStatus.vin} />
                    </Grid>
                  ) : null}
                  {/* orderStatus.currentArrivalDate */}
                  {orderStatus.currentArrivalDate ? (
                    <Grid item xs={12} md={6}>
                      <DataCardSmall
                        title="Est. Arrival Date"
                        data={new Date(
                          orderStatus.currentArrivalDate
                        ).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                      />
                    </Grid>
                  ) : null}
                  {orderStatus.currentArrivalDate ? (
                    <Grid item xs={12} md={6}>
                      <DataCardSmall
                        title="Est. Arriving in"
                        data={
                          Math.round(
                            ((orderStatus.currentArrivalDate ?? 0) -
                              new Date().getTime()) /
                              (24 * 60 * 60 * 1000)
                          ).toString() + " days"
                        }
                      />
                    </Grid>
                  ) : null}
                  <WindowStickerCard vin={orderStatus.vin ?? ""} />
                  <BuildSheetCard vin={orderStatus.vin ?? ""} />
                  <Grid item xs={12} container>
                    <Button
                    className="m-auto"
                    startIcon={isPiiHidden ? <BiShow/> : <BiHide/>}
                    onClick={() => setIsPiiHidden(!isPiiHidden)} variant="outlined">{isPiiHidden ? "Show" : "Hide"} PII</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} className="p-3">
                <Container className="pb-3">
                  <Typography variant="h4" component="h3" align="center">
                    Vehicle Specs
                  </Typography>
                </Container>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  columnGap={2}
                  rowGap={2}
                >
                  <DataCardSmall
                    title="Year"
                    data={orderStatus.vehicle.year.toString()}
                  />
                  <DataCardSmall title="Make" data={orderStatus.vehicle.make} />
                  <DataCardSmall
                    title="Model Code"
                    data={orderStatus.vehicle.modelCode ?? ""}
                  />
                  <DataCardSmall
                    title="Trim Code"
                    data={orderStatus.vehicle.trimCode ?? ""}
                  />
                  <DataCardSmall
                    title="Model"
                    data={orderStatus.vehicle.model}
                  />

                  <DataCardSmall
                    title="Interior Code"
                    data={orderStatus.vehicle.interiorOptionCode ?? ""}
                  />
                  <DataCardSmall
                    title="Paint Code"
                    data={orderStatus.vehicle.paintOptionCode ?? ""}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={6} className="p-3">
                <Container>
                  <Typography
                    variant="h4"
                    component="h3"
                    align="center"
                    className="pb-3"
                  >
                    Timeline
                  </Typography>
                </Container>
                <Timeline position="right">
                  {orderStatus.timeline.map((item, index) => (
                    <TimelineItem key={index}>
                      {item.statusUpdateDate ? (
                        <TimelineOppositeContent>
                          {new Date(item.statusUpdateDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                        </TimelineOppositeContent>
                      ) : null}
                      <TimelineSeparator>
                        <TimelineDot
                          color="primary"
                          variant={
                            item.statusUpdateDate ? "filled" : "outlined"
                          }
                        />
                        {index + 1 < orderStatus.timeline.length ? (
                          <TimelineConnector />
                        ) : null}
                      </TimelineSeparator>

                      <TimelineContent>
                        {item.statusCode} - {item.statusDesc}
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>
              <Grid item xs={12} className="p-3">
                <Typography
                  variant="h4"
                  component="h3"
                  align="center"
                  className="pb-3"
                >
                  Option Codes
                </Typography>
                <OptionCodesGrid vehicle={orderStatus.vehicle} />
              </Grid>
              <Grid item xs={12} className="p-3">
                <Typography
                  variant="h4"
                  component="h3"
                  align="center"
                  className="pb-3"
                >
                  Dealership
                </Typography>
                <Typography
                  variant="h5"
                  component="h4"
                  align="center"
                  className="p-3"
                  sx={{
                    textTransform: "uppercase",
                    letterSpacing: "0.1em",
                    fontWeight: "light",
                  }}
                >
                  {orderStatus.dealer.name}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  columnGap={2}
                  rowGap={2}
                >
                  <DataCardSmall
                    title="Address"
                    data={orderStatus.dealer.address}
                  />
                  <DataCardSmall title="City" data={orderStatus.dealer.city} />
                  <DataCardSmall
                    title="State"
                    data={orderStatus.dealer.state}
                  />
                  <DataCardSmall title="ZIP" data={orderStatus.dealer.zip} />
                  <DataCardSmall
                    title="Phone Number"
                    data={orderStatus.dealer.phoneNumber ?? "N/A"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : null}
    </Layout>
  );
};

export default OrderStatusPage;
