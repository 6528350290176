import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";


const GetAlertsButton = ()  => {
  const [open, setOpen] = React.useState(false);
  const [emailAddress, setEmailAddress] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const handleFormValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailSub = () => {
    if (emailAddress === "") {
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", emailAddress);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded
    };
    
    setIsLoading(true);
    fetch(`${process.env.GATSBY_API_URL}/api/v1/tracker/siteEmailAlert`, requestOptions)
      .then(
        (res) => {
          if (res.status !== 200) {
            console.error("Email API Status", res.status);
          }

          setIsLoading(false);
          setOpen(false);
        },
        (error) => {
          console.error("API Error: " + error);
          setIsLoading(false);
          setOpen(false);
        }
      );
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen} size="large"><Typography variant="button" className="p-3 px-5" sx={{letterSpacing: "0.25em", fontSize: "1.25em"}}>Get Alerts</Typography></Button>
      <Dialog open={open} onClose={handleClose} PaperProps={{sx:{backgroundColor: "black"}}}>
        <DialogTitle>Coming Soon!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We will be launching alerts for your order status changes. Enter your email address below to be one of the first to know when this feature is released.
          </DialogContentText>
          <TextField
            autoFocus
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="filled"
            margin="normal"
            onChange={handleFormValueChange}
            value={emailAddress}
          />
          {isLoading ? (<LinearProgress color="success" />) : (null)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEmailSub} color="success">Subscribe</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
};

export default GetAlertsButton;
